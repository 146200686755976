export const commonAttachmentLibraryFields = `
    id
    originFilename
    filename
    mimetype
    extension
    relativePathSegments
    width
    height
    size
    url
    meta
`;
