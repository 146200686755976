<div class="flex flex-shrink-0 items-center">
    <button
        mat-flat-button
        class="ml-3"
        [ngClass]="{ 'rounded-r-none pr-0': hasMenu }"
        [color]="color"
        [disabled]="disabled"
        [type]="mainButtonType"
        [attr.form]="mainButtonForm"
        [routerLink]="routerLinkMainButton"
        (click)="mainButtonClick.emit($event)"
    >
        <ng-container *ngTemplateOutlet="splitMainButtonContentTemplate.templateRef; context: { }">
        </ng-container>
    </button>
    <ng-container *ngIf="hasMenu">
        <button
            mat-flat-button
            class="rounded-l-none p-0 min-w-12"
            [ngClass]="{ 'disabled-main-button': disabled }"
            [color]="color"
            [matMenuTriggerFor]="menu"
        >
            <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
            >
                expand_more
            </mat-icon>
        </button>
        <mat-menu
            #menu="matMenu"
            xPosition="before"
        >
            <ng-container *ngTemplateOutlet="splitMenuButtonsTemplate.templateRef; context: { }">
            </ng-container>
        </mat-menu>
    </ng-container>
</div>