<div
    class="attachment-item"
    [formGroup]="formGroup"
>
        <div>
            <div class="attachment-img">
                <au-image-input
                    *ngIf="
                        formGroup.get('mimetype').value === 'image/jpeg' ||
                        formGroup.get('mimetype').value === 'image/png' ||
                        formGroup.get('mimetype').value === 'image/webp'
                    "
                    #image
                    formControlName="url"
                    imgClass="w-full h-auto"
                >
                </au-image-input>
                <!-- <fa-icon    *ngIf="attachment.get('mimetype').value === 'application/pdf'"
                            [icon]="['fas', 'file-pdf']"
                            size="8x"></fa-icon> -->
            </div>
            <div class="attachment-over">

                <div class="attachment-over-uncovered">
                    <h4 class="attachment-title">
                        {{ attachmentFamily?.name }}
                    </h4>
                    <p class="attachment-sub">
                        {{ attachment?.title }}
                    </p>
                    <p class="attachment-size">
                        {{ attachment?.size | sizeFormat }}
                    </p>
                    <a
                        class="attachment-download"
                        (click)="handlerDownload()"
                    >
                        <i class="material-icons">cloud_download</i>
                    </a>
                    <a
                        #openOver
                        class="attachment-edit-open"
                    >
                        <i class="material-icons">mode_edit</i>
                    </a>
                </div>

                <div class="attachment-over-covered">
                    <a
                        #closeOver
                        class="attachment-edit-close"
                    >
                        <i class="material-icons">clear</i>
                    </a>

                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            [placeholder]="'alt' | attachmentTranslate | async"
                            formControlName="alt"
                        >
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            [placeholder]="'title' | attachmentTranslate | async"
                            formControlName="title"
                        >
                    </div>

                    <div class="form-group">
                        <select
                            formControlName="familyId"
                            class="form-control"
                            (change)="handlerChangeFamily($event)"
                        >
                            <option value="">
                                {{ 'selectFamily' | attachmentTranslate | async }}
                            </option>
                            <option
                                *ngFor="let family of families"
                                [value]="family.id"
                            >
                                {{ family.name }}
                            </option>
                        </select>
                        <div class="select-back">
                            <i class="material-icons">
                                keyboard_arrow_down
                            </i>
                        </div>
                    </div>

                    <div
                        *ngIf="showCropButton"
                        class="form-group"
                    >
                        <button
                            mat-flat-button
                            color="accent"
                            type="button"
                            (click)="activeCropHandler()"
                        >
                            <span>{{ 'crop' | attachmentTranslate | async }}</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
</div>
<a
    class="remove-item"
    (click)="handlerRemoveItem()"
>
    <i class="material-icons">
        clear
    </i>
</a>
