import { FuseNavigationItem } from '@fuse/components/navigation';

export const starlightNavigation: FuseNavigationItem = {
    id         : 'starlight',
    title      : 'Starlight',
    type       : 'collapsable',
    iconFontSet: 'material-symbols-outlined',
    icon       : 'motion_mode',
    children   : [
        {
            id   : 'places',
            title: 'Place',
            type : 'basic',
            icon : 'mat_outline:place',
            link : '/starlight/place',
        },
        {
            id   : 'experiences',
            title: 'Experience',
            type : 'basic',
            icon : 'mat_outline:attractions',
            link : '/starlight/experience',
        },
        {
            id   : 'placeModalities',
            title: 'PlaceModality',
            type : 'basic',
            icon : 'mat_outline:beenhere',
            link : '/starlight/place-modality',
        },
        {
            id   : 'experienceCategories',
            title: 'ExperienceCategory',
            type : 'basic',
            icon : 'mat_outline:snippet_folder',
            link : '/starlight/experience-category',
        },
    ],
};