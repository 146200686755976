<input
    #input
    type="file"
    [attr.multiple]="multiple? '' : null"
    [attr.accept]="accept"
>
<span
    class="filename"
    [title]="fileNames"
>
    {{ fileNames }}
</span>