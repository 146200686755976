<div class="relative">
    <p class="absolute top-0 left-0.5 bg-white px-2 pt-1 origin-center -translate-y-1/2 scale-75 text-base z-999 pointer-events-none">
        <span class="opacity-60">{{ label }}</span>
    </p>
    <div class="rounded-md border hover:border-2 focus-within:border-2 p-0.5 hover:p-px focus-within:p-px transition-all">
        <!-- main -->
        <div
            class="flex flex-auto pt-0 px-2 overflow-y-auto"
            cdkScrollable
        >
            <ng-content></ng-content>
        </div>
    </div>
</div>