import { FuseNavigationItem } from '@fuse/components/navigation';
import { oAuthNavigation } from './apps/o-auth/o-auth.navigation';
import { iamNavigation } from './apps/iam/iam.navigation';
import { starlightNavigation } from './apps/starlight/starlight.navigation';
import { commonNavigation } from './apps/common/common.navigation';

export const adminNavigation: FuseNavigationItem[] = [
    starlightNavigation,
    commonNavigation,
    iamNavigation,
    oAuthNavigation,
];