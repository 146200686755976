export const environment = {
    production: false,
    debug     : true,
    api       : {
        graphql: 'https://crius-dev-api.beopen.cloud/graphql',
        rest   : 'https://crius-dev-api.beopen.cloud',
    },
    lang: {
        base    : 'es',
        fallback: 'es',
        langs   : ['es','en'],
    },
    oAuth: {
        applicationCode  : 'starlight',
        applicationSecret: '$c5Wf1bt82@TVdbcE7cx%DoqRCYyFe8iwYhh@&EtbQX3WopOTVU92pjx7qKq',
    },
};
